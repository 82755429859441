.cong-page {
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-image: url('./../Assets/CongPage/stuti final.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
}

.cong-page_bought {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 5%;
}
