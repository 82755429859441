.tile-pop {
  background-size: cover;
  background-position: center;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  vertical-align: center;
  text-align: center;
  backdrop-filter: contrast(15%);
  /* backdrop-filter: brightness(25%); */
  /* backdrop-filter: blur(10px); */
  /* opacity: 0.2; */
}

.tile-pop_inner {
  display: flex;
  flex-direction: column;
  background-image: url('./../Assets/MintPage/alert copy 1.png');
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
  justify-content: center;
  background-size: 90%;
  height: 540px;
  width: 540px;
  margin: auto;
}

.tile-pop_inner_txt {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  margin-left: 21%;
  margin-right: 20%;
  width: 300px;
}

.tile-pop_inner_btnimg {
  display: flex;
  flex-direction: row;
  padding-top: 2%;
}

.tile-pop_inner_content {
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.tile-pop_inner_img {
  width: 140px;
  height: 160px;
}
