.nft {
  width: 21%;
  padding-bottom: 5%;
  
}

.nft_image {
  width: 256px;
  height: 256px;
  border: 1px solid ;
  border-color: black; 
  padding: 5%;
}

.nft_name {
  width: 256px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #474747;
}

.nft_desc {
  width: 256px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: #474747;
}
