.token-bought_all-nft {
  display: flex;
  flex-direction: row;
}

.token-bought_nft {
  display: flex;
  flex-direction: column;
}

.token-bought_nft_img {
  width: 149px;
  height: 149px;
}

.token-bought_nft_name {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  padding: 3%;
  color: #ffffff;

  text-shadow: 0px 3.45503px 3.45503px rgba(0, 0, 0, 0.25);
}

.token-bought_tokens {
  display: flex;
  flex-direction: row;
  /* margin-top: 20%; */
  margin-top: 4%;
  margin-bottom: 5%;
}
