.collections {
  background-size: cover;
  background-position: center;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
}

.collections::after {
  content: '';
  background-image: url('./../Assets/Background_gif.gif');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: -1;
  inset: 0;
  opacity: 0.05;
}

.collections_header {
  padding-top: 4%;
  text-align: center;
}

.collections_nft {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 95%;
  margin: auto;
  margin-top: 5%;
  gap: 2%;
}

.collections_token {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 95%;
  margin: auto;
  margin-top: 5%;
  gap: 2%;
}

.collection_go-back {
  padding-bottom: 5%;
  justify-content: center;
  width: 100%;
  display: flex;
}

.collection_go-back_btn {
  width: 391px;
  height: 38px;
  background: #474747;
  border: 1px solid #474747;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 350;
  font-size: 13px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.token_plus {
  width: 21%;
}

.token_plus_box {
  width: 256px;
  height: 256px;
  background: #ffffff;
  border: 0.978944px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.token_plus_btn {
  border: none;
  background-color: transparent;
}

.token_plus_img {
  cursor: pointer;
}

.others {
  text-align: center;
  position: relative;
  margin: 3.2%;
}

.others_fortune {
  width: 81px;
  height: 58px;
  position: absolute;
  margin: auto;
  bottom: 0;
  left: 0;
  top: 494px;
  right: 543px;
}

.others_indexx {
  width: 81px;
  height: 59px;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  left: 132px;
  bottom: 483px;
}

.others_sorekt {
  width: 70px;
  height: 47px;
  position: absolute;
  margin: auto;
  top: 0;
  right: 509px;
  left: 0;
  bottom: 263px;
}

.others_skull-punks {
  width: 68px;
  height: 47px;
  position: absolute;
  margin: auto;
  top: 273px;
  right: 0;
  left: 518px;
  bottom: 0;
}

.others_zombie-labs {
  width: 96px;
  height: 48.37px;
  position: absolute;
  margin: auto;
  top: 110px;
  right: 325px;
  left: 0;
  bottom: 0;
}
