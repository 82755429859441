.token {
  width: 21%;
  padding-bottom: 5%;
}

.token_img-box {
  width: 256px;
  height: 256px;
  background: #ffffff;
  border: 0.978944px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
}

.token_image {
  width: 178px;
  height: 178.17px;
}

.token_name {
  width: 256px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #474747;
  /* width: fit-content; */
}

.token_desc {
  width: 256px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: #474747;
}
