.App {
  text-align: center;
  background-image: url('./../Assets/mint page background.png');
  background-size: cover;
  background-position: center;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  vertical-align: center;
  flex-direction: column;
  align-items: center;
}

.App__content {
  background-image: url('./../Assets/mint page grey BG.png');
  background-size: cover;
  background-position: center;
  display: flex;
  height: 540px;
  width: 540px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* margin: auto; */
}

.App__content__gif {
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  width: 98px;
  height: 95px;
  border: 0.14cm solid rgb(66, 65, 65);
}

.App__content__unique {
  margin: auto;
  height: fit-content;
  padding-top: 10px;
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 18.45px;
  line-height: 23px;
  color: #ffffff;
  opacity: 0.85;
}

.App__content__sorekt {
  /* width: 240.12px; */
  height: 80px;
  margin: auto;
}

.App__content__nft {
  width: auto;
  height: auto;
  margin-top: -3%;
  margin-bottom: auto;
  margin-left: 10px;
  font-weight: 370;
  font-size: 30px;
  color: #ffffff;
  text-shadow: 0px 3.45503px 3.45503px rgba(0, 0, 0, 0.25);
}

.connect-wallet {
  margin-top: 7%;
}
.minting {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 18%;
  flex-wrap: wrap;
}

.plus-minus {
  margin: auto;
  flex: row;
  margin-top: -40%;
  position: relative;
}

.App__content__circle-plus {
  position: absolute;
  top: -10px;
  bottom: 0;
  left: 35px;
  right: 0;
  width: 30.87px;
  height: 29.84px;
}

.App__content__plus {
  position: absolute;
  top: -2px;
  bottom: 0;
  left: 38px;
  right: 0;
  cursor: pointer;
}

.App__content__circle-minus {
  position: absolute;
  top: -10px;
  bottom: 0;
  left: -60px;
  right: 0;
  width: 30.87px;
  height: 29.84px;
  cursor: pointer;
}

.App__content__minus {
  cursor: pointer;
  position: absolute;
  height: fit-content;
  width: fit-content;
  top: 3px;
  bottom: 0;
  left: -50px;
  right: 0;
}

.App__content__num {
  position: absolute;
  top: -30px;
  bottom: 0;
  left: -44px;
  right: 0;
  color: white;
  height: fit-content;
  width: fit-content;
  font-size: 45px;
  text-align: center;
  width: 90px;
}

.App__content__mint {
  margin-top: 30%;
}

.App_content_btn_div {
  margin-top: -3.5%;
  padding-left: 2%;
}
.App_content_btn {
  width: 160px;
  height: 38px;
  background: #635e5e;
  border: 1px solid #474747;
  font-family: 'Avenir';
  font-style: normal;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.App_content_btn:hover {
  background: #474747;
}
