.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding-top: 4%;
  gap: 3%;
}

.nav-bar_icons {
  position: relative;
  height: fit-content;
  width: fit-content;
}

.nav-bar_icons_hover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  height: 82px;
  transition: opacity 0s;
}

.nav-bar_icons:hover .nav-bar_icons_hover {
  transform: scale(1.2);
  opacity: 1;
}

.nav-bar_icons a.active .nav-bar_icons_hover {
  transform: scale(1.2);
  opacity: 1;
}
