.mint-page {
  background-image: url('./../Assets/MintPage/mint 3.png');
  background-color: #1e1c1c;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.mint-page_content {
  background-image: url('./../Assets/MintPage/alert copy 1.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 651px;
  height: 651px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mint-page_content_sorekt {
  padding-top: 75%;
  filter: drop-shadow(0px 3.15945px 3.15945px rgba(0, 0, 0, 0.25));
}

.mint-page_content_nft_txt {
  font-family: 'Avenir', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 38.9971px;
  line-height: 47px;
  color: #000000;
  margin-top: -10%;
  padding-right: 10px;
  text-shadow: 0px 3.45503px 3.45503px rgba(0, 0, 0, 0.25);
}

.mint-page_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 90%;
  padding-top: 4%;
  padding-left: 5%;
}

.mint-page_desc {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 90%;
  padding-left: 5%;
}

.mint-page_desc_txt {
  font-weight: 350;
  font-size: 15px;
  color: #000000;
  line-height: 18px;
  margin-top: 1%;
  font-family: 'Avenir', sans-serif;
  font-weight: 400;
}

.mint-page__contents__rights {
  font-family: 'Avenir', sans-serif;
  font-weight: 350;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  padding-top: 8%;
}
